.form-floating>.form-control:focus~label {
    color: $primary;
}

.form-floating>label {
    opacity: 1;
}

.form-floating {
    >.form-control,
    {
        height: auto !important;
    }
}