/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.minHeight100vh {
  min-height: 100vh;
}

.z-index-2 {
  z-index: 2;
}

.bg-navigation-badge {
  color: #fff;
  background-color: rgba(152, 143, 244, 1);
  border: 1px solid #fff;
}

.brand-logo {
  color: #475f7b !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 26px !important;
}
.collapsedLogo {
  width: 55px;
  font-size: 14px !important;
}
.logo-wrapper {
  width: 70px;
  height: 70px;
}
.logo-img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.avatar-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.navigation-title--small {
  margin-bottom: 5px;
  font-size: 0.65rem;
}

.error {
  color: #ea5455;
}
.error-border {
  border: 1px solid #ea5455;
}
.orders-col {
  border-radius: 4px;
  padding: 10px 5px 10px;
  &-header {
    margin-bottom: 10px;
  }
  &__title {
    white-space: nowrap;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}

.desk-card {
  max-width: 351px; // было 241px
  width: 100%;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    box-shadow: 2px 2px 10px 2px #eaeaeb;
  }
  &__line {
    width: 5px;
    height: auto;
    color: transparent;
  }
  &_small {
    font-size: 12px;
    line-height: 12px;
  }
  &__description {
    width: 239px;
    overflow: hidden;
  }
}

.input-order {
  padding: 8px;
  font-size: 11px;
  line-height: 13px;
  &_tall {
    padding: 0.69rem 1rem;
    line-height: 1.45;
    font-size: 1rem;
    max-height: 41px;
  }
  &__label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
    &_big {
      font-size: 16px;
      white-space: nowrap;
      @media (max-width: 766px) {
        font-size: 13px;
        line-height: 12px;
      }
    }
  }
  &__btn {
    font-size: 12px;
    padding: 10px 15px;
  }
}

.empty-field {
  min-height: 100vw;
}

.sidebar-xxl {
  width: 60rem !important;
}

.search-result {
  width: 283px;
  top: 55px;
}

.label_floating {
  font-size: 11px;
  line-height: 11px !important;
  margin-bottom: 0px !important;
  padding: 10px 10px 5px !important;
  white-space: nowrap;
}

.text-align-start {
  text-align: start;
}

.grid-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
.project-card {
  &:hover {
    box-shadow: 0px 12px 20px 10px #eaeaeb;
  }
}
.projects {
  &-wrapper {
    &-height {
      height: calc(100vh - 255px) !important;
    }
    &-scrollable {
      height: calc(100% - 3.6em - 60px) !important;
    }
  }
  &-avatars {
    &-item {
      height: 32px !important;
      width: 32px !important;
      margin-left: -8px;
    }
  }
  &-to-do {
    &__title {
      max-width: 750px;
      width: 100%;
    }
  }
  &-add-time {
    &__input {
      font-size: 1.3rem;
    }
    &__date {
      border: none;
      background-color: transparent;
      color: #7367f0 !important;
      font-weight: 700;
      padding: 0;
      cursor: pointer;
      width: 100%;
      max-width: 100px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.todo-application .content-area-wrapper {
  border: none !important;
}

.task-sidebar {
  width: auto !important;
}
.task-list {
  flex-grow: 1;
  border-left: none !important;
  max-width: none !important;
  width: auto !important;
}
.listGridViewButton-wrapper {
  min-width: 100px;
  width: 115px;
}
.flatpickr-time {
  border: 1px solid #ea5455 !important;
}
.refLink_table .rdt_TableCell {
  height: auto !important;
  color: #6e6b7b !important;
}
.order-list-datepicker {
  background-color: #fff !important;
}
.tasks-no-results {
  margin: 10px;
  border: 1px dashed #ebe9f1;
  border-radius: 10px;
}

input[type='color'] {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  width: 38px;
  height: 38px;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
}

.accordion-header {
  &-projects {
    & .accordion-button {
      gap: 15px;
      padding: 0 !important;
      color: #7367f0 !important;
      background-color: transparent !important;
      font-weight: 500;
      &:after {
        order: -1;
        margin-left: 0 !important;
      }
    }
  }
}
.accordion-body {
  padding: 0 !important;
}
.select__control {
  border-color: hsl(0, 0%, 80%) !important;
  padding-top: 0.11rem;
  padding-bottom: 0.11rem;
  &::placeholder {
    color: rgba(51, 51, 51, 0.7);
  }
}
.width-fit-content {
  width: fit-content !important;
}
.height-fit-content {
  height: fit-content !important;
}
@media (max-width: 992px) {
  .desk-card {
    max-width: 100%;
  }
  .empty-field {
    min-height: 100%;
  }
  .logo-wrapper {
    width: 50px;
    height: 50px;
  }
  .logo-img {
    width: 50px;
    height: 50px;
  }
  .sidebar-xxl {
    width: 50rem !important;
  }
}

@media (max-width: 766px) {
  .sidebar-xxl {
    width: 100% !important;
  }
}
